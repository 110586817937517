<template>
 <!-- 评审会批复审核详情 -->
  <div class="contractFileReview">
    <back-title lable='评审会批复审核'/>
    <!-- 通用审核组件 -->
    <audit-details class="rigtcontent" businessType='htwj' :finReviewOpinionInfoVOS='finReviewOpinionInfoVOS' processStatus='CONTRACT' :type='type' :fileId='fileId' :fileType="fileType" :filelist='filelist' />
  </div>
</template>
<script>
import AuditDetails from '../../components/auditdetails.vue'
import { fileListApi, progressApi } from '@/api/businessApi'
import BackTitle from '../../components/backtitle.vue'
import Storage from '@/utils/storage'
export default {
  name: 'contractFileReviewFactor',
  components: { AuditDetails, BackTitle },
  data () {
    return {
      fileId: '',
      fileType: '',
      type: '',
      activeid: '',
      filelist: [
        {
          label: '合同文件',
          key: 'htwj',
          child: [

          ],
          id: '1'
        },
        { label: '其他资料', child: [], key: 'qtzl', id: '2' }
      ],
      activeNames: '1',
      finReviewOpinionInfoVOS: []
    }
  },
  computed: {
    api () {
      return fileListApi
    },
    summitapi () {
      return progressApi
    }
  },
  watch: {
    filelist: {
      handler () {
        this.changeFile(this.filelist[0].child[0].fileListDetailVOS[0])
      },
      deep: true
    }
  },
  methods: {
    // 选中文件
    changeFile (elem) {
      this.activeid = elem.currentFileId
      this.fileId = elem.currentFileId
      this.fileType = elem.suffix
    },
    // handleClick (tab, event) {
    //   if (this.filelist[this.activeNames * 1 - 1].child.length > 0) {
    //     this.changeFile(this.filelist[this.activeNames * 1 - 1].child[0])
    //   }
    // },
    // 获取文件信息
    getFileData () {
      const examine = Storage.getSession('examine')
      const params = {
        businessId: examine.businessId,
        instanceId: examine.instanceId
      }
      if (this.type === 'V') {
        params.businessId = examine.keyId
        params.instanceId = examine.instanceId
      }

      this.api.getContracthandle(params).then(res => {
        this.finReviewOpinionInfoVOS = res.data.finReviewOpinionInfoVO
        if (res.data.finFileListInfoVO) {
          // this.filelist = res.data.finFileListInfoVO
          res.data.finFileListInfoVO.forEach((item) => {
            this.filelist.forEach((elem) => {
              if (item.fileGroupCode === elem.key) {
                elem.child.push(item)
              }
            })
          })
        }
      })
    }
  },
  mounted () {
    this.getFileData()
  },
  created () {
    this.type = this.$route.query.type
  }

}
</script>
<style lang="scss" scoped>
.contractFileReview{
  height: calc(100% - 50px);
}
.rigtcontent{
  height: calc(100% - 22px);
}
.fileitem {
  background: linear-gradient(90deg, #88ACFF 0%, #FFFFFF 100%);;
  display: flex;
  width: 100%;
  padding: 0 9px;
  flex-wrap: wrap;
  .filename {
    font-size: 14px;
    font-weight: 400;
    color: #38373a;
    cursor: pointer;
    padding: 8px 0;
  }
  .filename:hover{
    color: #B2662F;
  }
  .active{
    color: #B2662F;
    border-bottom:2px solid #2862E7 ;
  }
  > div {
    padding-right: 15px;
  }
}
</style>
